<template>
  <div class="text-left">
    <div class="form-group row mt-4">
      <span class="col-md-3">status</span>
      <span class="col-md-6">{{ request.status | toRequestStatusDescription }}</span>
    </div>
    <div class="form-group row mt-4">
      <span class="col-md-3">request date</span>
      <span class="col-md-6">{{ request.requestDate | toShortDateString }}</span>
    </div>
    <div class="form-group row mt-4">
      <span class="col-md-3">number of vacation days</span>
      <span class="col-md-6">{{ request.numberOfDays }}</span>
    </div>
    <div class="form-group row mt-4">
      <span class="col-md-3">remarks</span>
      <textarea class="col-md-6 p-2" rows="3" disabled>{{ request.remarks }}</textarea>
    </div>
  </div>
</template>


<script>
  import { toRequestStatusDescription } from '@/filters/enum'
  import { toShortDateString } from '@/filters'

  export default {
    name: 'RequestView',
    props:{
      request: {
        type: Object,
        required: false,
      }
    },
    filters: {
      toRequestStatusDescription,
      toShortDateString
    }
  }
</script>

